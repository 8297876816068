<template>
  <div
    class="flex min-h-full items-center justify-center px-4 py-12 sm:px-6 lg:px-8"
  >
    <div class="w-full max-w-md space-y-8">
      <div>
        <h2
          class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
        >
          Login
        </h2>
      </div>
      <form class="mt-8 space-y-6" action="#" method="POST">
        <input type="hidden" name="remember" value="true" />
        <div class="-space-y-px rounded-md shadow-sm">
          <div>
            <label for="email-address" class="sr-only">{{
              $t("email_label")
            }}</label>
            <input
              id="email-address"
              @value="form.username"
              name="email"
              type="email"
              autocomplete="email"
              required
              class="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              :placeholder="$t('email_placeholder')"
            />
          </div>
          <div>
            <label for="password" class="sr-only">{{
              $t("password_label")
            }}</label>
            <input
              id="password"
              @value="form.password"
              name="password"
              type="password"
              autocomplete="current-password"
              required
              class="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              placeholder="Password"
            />
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <label for="remember-me" class="ml-2 block text-sm text-gray-900"
              >Remember me</label
            >
          </div>

          <div class="text-sm">
            <a
              href="#"
              class="font-medium text-indigo-600 hover:text-indigo-500"
              >{{ $t("reset_password") }}</a
            >
          </div>
        </div>

        <div>
          <button
            type="submit"
            @click="userLogin"
            class="group relative flex w-full justify-center rounded-md border border-transparent bg-neutral-700 px-4 py-2 text-sm font-medium text-white hover:bg-neutral-400 focus:bg-neutral-400 focus:outline-none focus:ring-2 focus:ring-offset-2"
          >
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
              <LockClosedIcon
                class="h-5 w-5 text-stone-500 group-hover:text-stone-400"
                aria-hidden="true"
              />
            </span>
            Sign in
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script setup lang="ts">
import { LockClosedIcon } from "@heroicons/vue/20/solid";
import qs from "qs";

const { $auth } = useNuxtApp();
const auth = useAuth();
const form = reactive({ scope: "user", username: "", password: "" });

async function userLogin() {
  try {
    console.log("triggerd");
    let response = await $auth.loginWith("local", {
      data: qs.stringify(form),
    });
    valid = true;
    this.$router.push("/");
  } catch (err) {
    this.valid = false;
    console.log(err);
  }
}
</script>
